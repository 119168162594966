import React, { useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import ActionButton from "../../common/components/ActionButton";
import { useErrorUpdateContext } from "../../contexts/ErrorContext";
import DefaultPage from "./DefaultPage";
import styles from "../../styles/components/pages/UtilPage.module.css"
import { exportPdf } from "../../export/pdf/exportPdf"
import { exportPptx } from "../../export/pptx/exportPptx"
import api from "../../rest/api";

export interface UtilPageProps {}

const UtilPage: React.FC<UtilPageProps> = () => {
  const updateError = useErrorUpdateContext();

  const [isLoading, setLoading] = useState(false);

  const [pharmacies, setPharmacies] = useState<
    { title: string; value: string }[]
  >([]);  

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const data = await api.getAllPharmacies();
        if (!ignore) {
          setLoading(false);
          const res = data.map((x) => ({
            title: x.name,
            value: x.id.toString()
          }))
          setPharmacies(res);
        }
      } catch (error) {
        setLoading(false);
        updateError(error);
      }
    }

    fetchData();
    return () => {
      ignore = true;
    }
  })

  const onPdfExportAll = async () => {
    console.log("on pdf export all");
    console.log(pharmacies);
    for (const p of pharmacies) {
      console.log(p.value + " " + p.title);
      const profile = await api.findPharmacyProfile( { id: Number(p.value) })
      await exportPdf(
        profile,
        profile.basicInfo?.name ?? "Unidentified SP",
        getDateForExportedDoc(new Date())
      )
    }
  };

  const onPptxExportAll = () => {
    console.log("on pptx export all");
    console.log(pharmacies);
  }
  
  const getDateForExportedDoc = (date: Date): string => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNum = date.getMonth();
    const month = months[monthNum];
    const year = date.getFullYear();
    return month + " " + year;
  };

  return (
    <DefaultPage
      name="Utility Page"
      header={{
        title: "Utility Page",
        backAction: false,
      }}
    >
      <div className={styles.container}>
        <div className={styles.exportActionContainer}>
        <ActionButton
          text="Export all profiles as PDF"
          type="secondaryAlt"
          onClick={onPdfExportAll}
          loading={isLoading}
        />
        <ActionButton
          text="Export all profiles as PPTX"
          type="secondaryAlt"
          onClick={onPptxExportAll}
        />
        </div>
      </div>
    </DefaultPage>
  );
};

export default withRouter(UtilPage);
